import { Component } from '@angular/core';
import { CrudService } from './services/crud.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  constructor(private crudService: CrudService) {
    this.getAppConfig()
  }

  getAppConfig() {
    this.crudService.getData('configuration/pwa').toPromise()
      .then((data: any) => {
        this.crudService.configuration = data.configuration;
        console.log(data)
      })
  }

}
