import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CrudService } from 'src/app/services/crud.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {

  @Input()
  title: string;

  @Input()
  backBtn: boolean;

  constructor(private crudService: CrudService,
    private router: Router,) { }

  ngOnInit() {
    console.log('backBtn: ', this.backBtn)
    if (this.backBtn) {
      console.log('backBtn true: ', this.backBtn)
    } else {
      console.log('backBtn false: ', this.backBtn)
    }
  }




  logout() {
    this.crudService.setAuthToken = null
    localStorage.clear();
    this.router.navigate(['login']);
  }


}
