import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { LoadingController } from '@ionic/angular';

@Injectable({
    providedIn: 'root'
})
export class UtilService {

    private myToast: any;

    constructor(
        private toast: ToastController,
        private loadingController: LoadingController
    ) { }

    showToast(message: string) {
        this.myToast = this.toast.create({
            message: message,
            duration: 2000
        }).then((toastData) => {
            console.log(toastData);
            toastData.present();
        });
    }

    HideToast() {
        this.myToast = this.toast.dismiss();
    }

    showHideAutoLoader(msg: string) {
        this.loadingController.create({
            message: msg,
            duration: 4000
        }).then((res) => {
            res.present();

            res.onDidDismiss().then((dis) => {
                console.log('Loading dismissed! after 4 Seconds', dis);
            });
        });

    }

    showLoader() {
        this.loadingController.create({
            message: 'Please wait...'
        }).then((res) => {
            res.present();
        });

    }

    hideLoader() {
        this.loadingController.dismiss().then((res) => {
            console.log('Loading dismissed!', res);
        }).catch((error) => {
            console.log('error', error);
        });

    }


}