import { Component } from '@angular/core';
import { NavController } from '@ionic/angular';
import { ModalController } from '@ionic/angular';
import { CrudService } from 'src/app/services/crud.service';
import { UtilService } from 'src/app/services/util.service';

@Component({
    selector: 'room-service-modal',
    templateUrl: 'room-service.html',
    styleUrls: ['room-service.scss']
})
export class RoomServiceModal {


    constructor(public navCtrl: NavController,
        public modalCtrl: ModalController,
        public crudService: CrudService,
        public utilService: UtilService) {
        console.log('configuration', crudService.configuration)
    }

    dismiss() {
        this.modalCtrl.dismiss();
    }

    async requestRoomService(roomServiceLabel: string) {
        this.utilService.showLoader()
        let requestBody = {
            "request": roomServiceLabel
        };
        let response = await this.crudService.saveData('room-service', requestBody).toPromise();
        this.utilService.hideLoader()
        this.utilService.showToast(response.response_data);
    }

}
