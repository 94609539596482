import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { RoomServiceModal } from "./home/room-service/room-service.page"
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RequestInterceptorService } from './services/request-interceptor.service';
import { HeaderComponent } from './component/header/header.component';
import { FooterComponent } from './component/footer/footer.component';
import { LoginPage } from './login/login.page';
import { LoginPageModule } from './login/login.module';
import { ComponentsModule } from './component/components.module';


@NgModule({
  declarations: [AppComponent, RoomServiceModal],
  entryComponents: [HeaderComponent, FooterComponent],
  imports: [IonicModule, BrowserModule, IonicModule.forRoot(), AppRoutingModule, HttpClientModule, ComponentsModule],
  providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: RequestInterceptorService,
    multi: true,
  },
  ],
  bootstrap: [AppComponent],
  exports: []
})
export class AppModule { }
